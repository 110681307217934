import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { OBS_TYPE } from './../../constants'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    heading: {
        fontSize: "1.25em",
    },
    button: {
    },
    nomargin: {
        marginBottom : '0',
    }
});

class ObservationEditDialogContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            observationText: '',
            observationType: '',
            observationRadius: 0,
            imgData: ''
        };
    }

    onSelectObstype = (obstype) => {
        this.setState({...this.state, observationType: obstype});
    }

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.value });
    }

    addObservationBtnDisabled = () => {
        if (this.state.observationText.length > 0 && this.state.observationText.length > 0)
            return false;
        return true;
    }
    fileUpload = (evt) => {
        let _this = this;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(evt.target.files[0]);
        fileReader.onload = (e) => {
            _this.setState({...this.state, imgData: fileReader.result});
        }
    }
    render() {
        const { onClose, ...other } = this.props;

        var observation = this.props.observation;
        if (observation === null) {
            return null;
        }

        return (
            <DialogContent>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-multiline-flexible"
                        label="Havainto"
                        multiline
                        rowsMax="4"
                        value={this.state.observationText}
                        onChange={this.handleChange('observationText')}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="standard-multiline-flexible"
                        label="Havaintosäde (m)"
                        value={this.state.observationRadius}
                        onChange={this.handleChange('observationRadius')}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                </Grid>

                {this.state.observationType.length == 0 &&
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        className={this.props.classes.button}
                        onClick={() => this.onSelectObstype(OBS_TYPE.TULIPALO)}>
                        {OBS_TYPE.TULIPALO}
                    </Button>
                    <Button
                        color="primary"
                        className={this.props.classes.button}
                        onClick={() => this.onSelectObstype(OBS_TYPE.RAJAHDYS)}>
                        {OBS_TYPE.RAJAHDYS}
                    </Button>
                    <Button
                        color="primary"
                        className={this.props.classes.button}
                        onClick={() => this.onSelectObstype(OBS_TYPE.ILMAVAARA)}>
                        {OBS_TYPE.ILMAVAARA}
                    </Button>
                    <Button
                        color="primary"
                        className={this.props.classes.button}
                        onClick={() => this.onSelectObstype(OBS_TYPE.ILMAHALYTYS)}>
                        {OBS_TYPE.ILMAHALYTYS}
                    </Button>
                </Grid>
                }
                {this.state.observationType.length > 0 &&
                <Grid item xs={12}>
                    <strong>{this.state.observationType}</strong>
                </Grid>
                }

                <Grid item xs={12}>
                    <label className="custom-file-upload">
                    <input type="file" accept="image/jpeg" onChange={this.fileUpload}/>
                        Lisää kuva</label>
                    <img src={this.state.imgData} height="100"/>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.button}
                        disabled={this.addObservationBtnDisabled()}
                        onClick={this.props.onClickSaveObservation.bind(this, this.state.observationText, this.state.observationType, this.state.observationRadius, this.state.imgData)}>
                        Tallenna
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.button}
                        onClick={this.props.onClickCloseBtn}>
                        Sulje
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
        );
    }
}

export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(ObservationEditDialogContent));