import Position from './position'
import User from './user'
import moment from 'moment'

class Report {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.position = new Position(json.position);
            this.type = json.type;
            this.reportedTs = moment(json.reportedTs);
            this.reporter = new User(json.reporter);
        } else {
            this.id = Math.floor(Math.random() * 1000000) + 1;
            this.position = new Position();
            this.type = '';
            this.reportedTs = moment();
            this.reporter = new User();
        }
    }
}

export default Report;
