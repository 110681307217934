import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import appstate from './appstate'
import authentication from './authentication'
import units from './units'
import missions from './missions'
import observations from './observations'
import mobile from './mobile'
import positionupdates from "./positionupdates";
import reports from './reports';
import user from "./user";

const allReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    appstate,
    authentication,
    units,
    missions,
    observations,
    mobile,
    positionupdates,
    reports,
    user,
  });
};

export default allReducers;