import React from 'react'
import {withTheme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Unit from '../domain/unit'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    button: {
        margin: theme.spacing.unit,
        width: "100%"
    },
    header: {
        //backgroundImage: `url(${backgroundImg})`,
        // height: '100vh',

        //filter: "blur(8px)",
        //webkitFilter: "blur(8px)",
    },
    progress: {},
    buttonProgress: {
        color: "green",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    unitItem: {
        fontSize: "1.25em",
    },
    unitSelected: {
        backgroundColor: "lightgray",
    },
});

// Params
// - show (bool)
// - onSubmit (function)
// - loginFailed (bool)
// - loginInProgress (bool)
class UnitSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this.getForm = this.getForm.bind(this)
        this.state = {
            btnDisabled: true,
            selectedUnitId: null,
            unitFlatList: [],
            isUserInAUnit: true,
        };
        this.containerStyle = {
            position: "absolute",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        };
    }

    onClickSave = () => {
        //this.props.onSubmit(this.state.username, this.state.password)
        this.props.onAddUserToUnit(this.state.selectedUnitId);
        this.setState({...this.state, isUserInAUnit: true});
    }

    onClickUnit = (id) => {
        this.setState({...this.state, btnDisabled: false, selectedUnitId: id});
    }

    getForm(props) {
        const classes = this.props.classes;
        return (
            <div className={classes.header}>
                <div className="container" style={this.containerStyle}>
                    <div className="row" style={{"marginTop": "50px"}}>
                        <div className="col" style={{"textAlign": "center"}}>
                            <div className="col" style={{
                                "margin": "auto",
                                "textAlign": "center",
                                "backgroundColor": "white",
                                "maxWidth": "500px",
                                "borderRadius": "10px"
                            }}>
                                <div style={{"padding": "1em"}}>
                                    <h2>Valitse yksikkösi</h2><br/>
                                    <div className="form">
                                        <div className="form-group">
                                            {props.units.map((unit) =>
                                                <div key={unit.id} className={classes.unitItem}
                                                     onClick={() => this.onClickUnit(unit.id)}
                                                >

                                                    {this.state.selectedUnitId == unit.id &&
                                                    <div
                                                        className={classes.unitSelected}>{unit.name}&nbsp;({unit.leader.userName})</div>
                                                    }
                                                    {this.state.selectedUnitId != unit.id &&
                                                    <div>{unit.name}&nbsp;({unit.leader.userName})</div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <br/>

                                        <div className="form-group" style={{"position": "relative"}}>

                                            <Button variant="contained" color="primary"
                                                    onClick={this.onClickSave}
                                                    className={classes.button}
                                                    disabled={this.state.btnDisabled}>
                                                Tallenna
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div style={{"color": "white"}}>My Ugly City&trade; &copy;&nbsp;2019 MUC Inc Oy</div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    isUserInAUnit = (userId, unitFlatList) => {
        for (let u of unitFlatList) {
            if (userId == u.leader.id) return true;
            if (u.members != null && u.members.length > 0) {
                for (let m of u.members) {
                    if (m.id == userId) return true;
                }
            }
        }
        return false;
    }

    unitsToFlatList = (units) => {
        let result = [];
        for (let u of units) {
            if (u.subUnits != null && u.subUnits.length > 0) {
                result = result.concat(this.unitsToFlatList(u.subUnits));
            }
            result.push(u);
        }
        return result;
    }

    componentDidMount() {
        if (this.props.units != null) {
            let unitFlatList = this.unitsToFlatList(this.props.units);
            let isUserInAUnit = this.isUserInAUnit(this.props.userProfile.id, unitFlatList);
            this.setState({...this.state, unitFlatList: unitFlatList, isUserInAUnit: isUserInAUnit});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.units != null) {
            let unitFlatList = this.unitsToFlatList(this.props.units);
            let isUserInAUnit = this.isUserInAUnit(this.props.userProfile.id, unitFlatList);
            if (isUserInAUnit != prevState.isUserInAUnit || unitFlatList.length != prevState.unitFlatList.length) {
                this.setState({...this.state, unitFlatList: unitFlatList, isUserInAUnit: isUserInAUnit});
            }
        }
    }

    render() {
        var dev = false
        var classes = ['login-container', 'login-container-show']
        if (process.env.NODE_ENV === 'development') {
            dev = true
        }
        if (!this.props.isAuthenticated || this.props.loginInProgress || this.props.units == null) {
            return null
        }

        if (this.state.isUserInAUnit) return null;
        return (
            <div className={classes.join(' ')}>
                <this.getForm dev={dev} units={this.state.unitFlatList}/>
            </div>
        )
    }
}

export default withStyles(styles)(withTheme()(UnitSelect));