import Ajax from './ajax'
import User from '../domain/user'
import moment from 'moment'
import CONFIG from '../config'
import * as mobile from './mobile'

export const LOGIN_IN_PROGRESS = 'authentication/LOGIN_IN_PROGRESS'
export const LOGOFF_IN_PROGRESS = 'authentication/LOGOFF_IN_PROGRESS'
export const LOGIN_AUTHENTICATED = 'authentication/LOGIN_AUTHENTICATED'
export const LOGIN_FAILED = 'authentication/LOGIN_FAILED'
export const LOGIN_NEW_PASSWORD_REQUIRED = 'authentication/LOGIN_NEW_PASSWORD_REQUIRED'
export const LOGIN_PASSWORD_CHANGE_IN_PROGRESS = 'authentication/LOGIN_PASSWORD_CHANGE_IN_PROGRESS'
export const LOGIN_PASSWORD_CHANGE_FAILED = 'authentication/PASSWORD_CHANGE_FAILED'
export const LOGGED_OFF = 'authentication/LOGGED_OFF'
export const AUTHENTICATION_FAILED = 'authentication/AUTHENTICATION_FAILED'
export const JWT_TOKEN = 'accessToken'
export const JWT_REFRESH_TOKEN = 'refreshToken'
export const ACCESS_TOKEN_VALID_UNTIL = 'accessTokenValidUntil'

const initialState = {
    authenticated: false,
    loginFailed: false,
    loginInProgress: false,
    logoffInProgress: false,
    userProfile: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_IN_PROGRESS:
            return {
                ...state,
                loginInProgress: true,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: {}
            }
        
        case LOGOFF_IN_PROGRESS:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: true,
                userProfile: {}
            }
        
        case LOGIN_AUTHENTICATED:
            console.log("authentication: "+ action.user);
            return {
                ...state,
                loginInProgress: false,
                authenticated: true,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: new User(action.user),
                newPasswordRequired: false,
                passwordChangeFailed: false,
                passwordChangeInProgress: false
            }
    
        case LOGIN_FAILED:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: true,
                logoffInProgress: false,
                userProfile: {}
            }
        case LOGGED_OFF:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: {}
            }
        case AUTHENTICATION_FAILED:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: {}
            }
        default:
            return state
    }
}

const SetTokenValuesToStorage = (access_token, refresh_token, access_token_valid) => {
    localStorage.setItem(JWT_TOKEN, access_token);
    mobile.updateToken(access_token);
    localStorage.setItem(ACCESS_TOKEN_VALID_UNTIL, moment().add(access_token_valid, 'seconds').unix());
}

const ClearTokenValuesFromStorage = () => {
    localStorage.removeItem(JWT_TOKEN);;
    mobile.updateToken("");
    localStorage.removeItem(ACCESS_TOKEN_VALID_UNTIL);
}

export const doLogin = (user, pass) => {
    return dispatch => {
        dispatch({
            type: LOGIN_IN_PROGRESS
        });
        Ajax.post("api/login", {username: user, password: pass}).then(function(resp){
            SetTokenValuesToStorage(resp.data.access_token, resp.data.refresh_token, resp.data.access_token_expires_in);
            dispatch({
                type: LOGIN_AUTHENTICATED,
                user: resp.data.user
            })
        }).catch(function(err){
            ClearTokenValuesFromStorage();
            dispatch({
                type: LOGIN_FAILED
            })
        })
    }
}

export const doLogoff = () => {
    return dispatch => {
        dispatch({
            type: LOGOFF_IN_PROGRESS
        });
        //Ajax.post("api/logoff").then(function(resp) {
            ClearTokenValuesFromStorage();
            dispatch({
                type: LOGGED_OFF
            });
        /*}).catch(function(err) {
            console.log(err);
            ClearTokenValuesFromStorage();
            dispatch({
                type: LOGGED_OFF
            });
        });*/
    }
}

export const AuthenticationFailed = () => {
    return {
        type: AUTHENTICATION_FAILED
    }
}

export const CheckAuthentication = () => {
    return dispatch => {
        Ajax.get("api/login").then(function(resp) {
            dispatch({
                type: LOGIN_AUTHENTICATED,
                user: resp.data.user
            });
        }).catch(function(err){
            console.log(err);
            ClearTokenValuesFromStorage();
            dispatch({
                type: LOGGED_OFF
            })
        });
    }
}

