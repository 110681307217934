import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent';
import blue from '@material-ui/core/colors/blue';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Mission from '../domain/mission';
import Unit from '../domain/unit';
import Position from '../domain/position';
import Observation from '../domain/observation';
import withMobileDialog from '@material-ui/core/withMobileDialog';


const styles = {
    heading: {
        fontSize: "1.25em",
    },
    button: {
        marginRight: "10px",
    },
    nomargin: {
        marginBottom : '0',
    },
    textField: {
        width: "100%",
    },
};



class MissionView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            missionReport: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mission !== null) {
            this.setState({
                missionReport: nextProps.mission.report
            });
        }
      }

    handleClose = () => {
        this.props.onClose();
    }
    onClickStartBtn = () => {
        this.props.changeMissionStatus(this.props.mission.id, "t", this.state.missionReport);
        this.props.onClose();
    }

    onClickSaveBtn = () => {
        this.props.changeMissionStatus(this.props.mission.id, this.props.mission.state, this.state.missionReport);
        this.props.onClose();
    }
    onClickCloseBtn = () => {
        this.props.onClose();
    }

    onClickMissionImpossibleBtn = () => {
        this.props.changeMissionStatus(this.props.mission.id, "!", this.state.missionReport);
        this.props.onClose();
    }

    onClickDoneBtn = () => {
        this.props.changeMissionStatus(this.props.mission.id, "v", this.state.missionReport);
        this.props.onClose();
    }

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.value });
    }

    render() {
        const { onClose, ...other } = this.props;
        if (this.props.mission == null) return null;
        return (
            <Dialog open={this.props.show} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">Tehtävä</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <strong>Tilanne</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.status}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Tehtävä</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.task}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Joukot</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.troops}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Lisämääreet</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.additionalInformation}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                    id="standard-multiline-flexible"
                                    label="Raportti"
                                    multiline
                                    value={this.state.missionReport}
                                    onChange={this.handleChange('missionReport')}
                                    className={this.props.classes.textField}
                                    margin="normal"
                                />
                        </Grid>
                        <Grid item xs={12}>
                            {((this.props.mission.state == "u") || (this.props.mission.state == "!")) &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickStartBtn}>
                                Aloita
                            </Button>
                            }
                            {this.props.mission.state == "t" &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickDoneBtn}>
                                Valmis
                            </Button>
                            }
                            {this.props.mission.state != "!" &&
                            <Button
                                variant="contained"
                                color="danger"
                                className={this.props.classes.button}
                                onClick={this.onClickMissionImpossibleBtn}>
                                Ei kykene
                            </Button>
                            }
                            <Button
                                variant="contained"
                                color="secondary"
                                className={this.props.classes.button}
                                onClick={this.onClickSaveBtn}>
                                Tallenna
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={this.props.classes.button}
                                onClick={this.onClickCloseBtn}>
                                Sulje
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}


export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(MissionView));