import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent';
import blue from '@material-ui/core/colors/blue';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Mission from '../domain/mission';
import Unit from '../domain/unit';
import Position from '../domain/position';
import Observation from '../domain/observation';
import {UNIT_STATUS, MARKER_TYPE} from './../constants'
import ObservationDialogContent from './dialogs/observationDialog'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    heading: {
        fontSize: "1.25em",
    },
    button: {
    },
    nomargin: {
        marginBottom : '0',
    },
    smaller: {
        fontSize: "0.75em",
    }
});

class MarkerInformationDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    clickNewMission = ()  => {
        this.props.onCloseMarkerInfo();
        this.props.onNewMission();
    }

    unitActiveMission = (user) => {
        if (this.props.missions != null && this.props.missions.length > 0) {
            for(let m of this.props.missions) {
                if (m.responsible.id === user.id) {
                    return m;
                }
            }
        }
        return null;
    }

    onCallClick = (number, event) => {
        if (this.props.isMobile()) {
            this.props.onPhoneCall(number);
        }
    };

    onStatusTextChange = (unit, event) => {
        this.props.changeUnitStatus(unit, 'TXT', event.target.value);
    };

    onStatusLightClick = (unit, statusField, status) => {
        this.props.changeUnitStatus(unit, statusField, status);
    };

    handleClose = () => {
        this.props.onCloseMarkerInfo();
    };

    onClickCloseBtn = () => {
        this.props.onCloseMarkerInfo();
    };

    onClickRemoveMarkerBtn = (type) => {
        this.props.onRemoveMarker(type);
    };

    getStatusDotStyle = (targetStatus, status) => {
        var opacity;
        var dotColor;

        if (targetStatus === status) {
            opacity = '1.0';
        } else {
            opacity = '0.4';
        }

        if (targetStatus === UNIT_STATUS.CRIT) {
            dotColor = 'red';
        } else if (targetStatus === UNIT_STATUS.WARN) {
            dotColor = 'yellow';
        } else {
            dotColor = 'green';
        }

        return this._getDotStyle(dotColor, opacity);
    };
    _getDotStyle = (dotColor, opacity) => {
        return {
            height: '25px',
            width: '25px',
            backgroundColor: dotColor,
            opacity: opacity,
            borderRadius: '50%',
            display: 'inline-block',
            borderColor: 'black',
            borderWidth: '1px',
            borderStyle: 'solid'
        }
    };

    render() {
        const { onClose, ...other } = this.props;
        let title = "";
        const mission = this.props.mission;
        const observation = this.props.observation;
        const unit = this.props.unit;
        const report = this.props.report;
        const userProfile = this.props.userProfile;

        let position = '';
        let userIsLeader = false;
        let activeMission = null;

        let phoneLink = null;

        if (mission != null) {
            position = mission.position.latitude + ", " + mission.position.longitude;
            title = "Tehtävä: ";
        }
        else if (observation != null) {
            position = observation.position.latitude + ", " + observation.position.longitude;
            if (userProfile.userName == 'Tarmo' && !observation.ack) {
                observation.ack = true;
                this.props.ackObservation(observation);
            }
            title = "Havainto: ";
        }
        else if (unit != null) {
            position = unit.position.latitude + ', ' + unit.position.longitude;
            title = 'Yksikkö: ' + unit.name;
            userIsLeader = (userProfile.id === unit.leader.id);
            activeMission = this.unitActiveMission(unit.leader);
            phoneLink = (<a href={'tel:'+unit.leader.phoneNumber}><img src='images/luuri_gre.png' width='48' height='48' onClick={this.onCallClick.bind(this, unit.leader.phoneNumber)}/></a>);
            if (this.props.isMobile()) {
                phoneLink = (<img src='images/luuri_gre.png' width='48' height='48' onClick={this.onCallClick.bind(this, unit.leader.phoneNumber)}/>);
            }
        }
        else if (report != null) {
            position = report.position.latitude + ', ' + report.position.longitude;
            title = 'Ilmoitus: ' + report.type;
            console.log(title);
        }

        return (
            <Dialog open={this.props.show} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">{title}<br/>
                    <span className={this.props.classes.smaller}>Sijainti: {position}</span>
                </DialogTitle>
                {mission != null &&
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <strong>Tilanne</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.status}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Tehtävä</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.task}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Joukot</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.troops}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Lisämääreet</strong>
                            <p className={this.props.classes.nomargin}>{this.props.mission.additionalInformation}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Vastuullinen</strong><p>{mission.responsible.userName}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickCloseBtn}>
                                Sulje
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickRemoveMarkerBtn.bind(this, MARKER_TYPE.TEHTAVA)}>
                                Poista tehtävä
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                }
                {observation != null &&
                    <ObservationDialogContent observation={observation} 
                    onClickRemoveMarkerBtn={this.onClickRemoveMarkerBtn} 
                    onCallClick={this.onCallClick} 
                    isMobile={this.props.isMobile}
                    userProfile={userProfile} 
                    onCloseMarkerInfo={this.props.onCloseMarkerInfo}/>
                }
                {unit != null &&
                <DialogContent>
                    <DialogContent>
                        <Grid container spacing={16}>
                            <Grid item xs={10}>
                                <strong>Johtaja:</strong> {unit.leader.userName}
                            </Grid>
                            { userIsLeader ? '' : (
                            <Grid item xs={2}>
                                {phoneLink}
                            </Grid>
                            )}

                            {activeMission &&
                                <Grid style={{border: '2px solid red'}} item xs={12}>
                                    <strong>Tehtävä:</strong> {activeMission.task}<br/>
                                    <strong>Tila:</strong> {activeMission.status}<br/>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={this.props.classes.button}
                                    onClick={this.clickNewMission}>
                                    Uusi tehtävä
                                </Button>
                            </Grid>
                            { 
                                userIsLeader && unit.members.map((member) => 
                                (
                                    <Grid container>
                                        <Grid item xs={10}>
                                            {member.userName}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {phoneLink}
                                        </Grid>
                                     </Grid>
                                ))
                            }

                            <Grid item xs={12}>
                                <TextField
                                    id="unitStatusText"
                                    label="Tilanne"
                                    fullWidth={true}
                                    defaultValue={unit.statusText}
                                    style={styles.textField}
                                    onChange={this.onStatusTextChange.bind(this, unit)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Henkilöstö:</strong>
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'HE', UNIT_STATUS.OK)} style={this.getStatusDotStyle(UNIT_STATUS.OK, unit.statusHE)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'HE', UNIT_STATUS.WARN)} style={this.getStatusDotStyle(UNIT_STATUS.WARN, unit.statusHE)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'HE', UNIT_STATUS.CRIT)} style={this.getStatusDotStyle(UNIT_STATUS.CRIT, unit.statusHE)} />
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Ajoneuvot:</strong>
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AJ', UNIT_STATUS.OK)} style={this.getStatusDotStyle(UNIT_STATUS.OK, unit.statusAJ)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AJ', UNIT_STATUS.WARN)} style={this.getStatusDotStyle(UNIT_STATUS.WARN, unit.statusAJ)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AJ', UNIT_STATUS.CRIT)} style={this.getStatusDotStyle(UNIT_STATUS.CRIT, unit.statusAJ)} />
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Aseet:</strong>
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AS', UNIT_STATUS.OK)} style={this.getStatusDotStyle(UNIT_STATUS.OK, unit.statusAS)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AS', UNIT_STATUS.WARN)} style={this.getStatusDotStyle(UNIT_STATUS.WARN, unit.statusAS)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AS', UNIT_STATUS.CRIT)} style={this.getStatusDotStyle(UNIT_STATUS.CRIT, unit.statusAS)} />
                            </Grid>

                            <Grid item xs={6}>
                                <strong>Ampumatarvikkeet:</strong>
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AT', UNIT_STATUS.OK)} style={this.getStatusDotStyle(UNIT_STATUS.OK, unit.statusAT)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AT', UNIT_STATUS.WARN)} style={this.getStatusDotStyle(UNIT_STATUS.WARN, unit.statusAT)} />
                            </Grid>
                            <Grid item xs={2}>
                                <div onClick={this.onStatusLightClick.bind(this, unit, 'AT', UNIT_STATUS.CRIT)} style={this.getStatusDotStyle(UNIT_STATUS.CRIT, unit.statusAT)} />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={this.props.classes.button}
                                    onClick={this.onClickCloseBtn}>
                                    Sulje
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
                }
                {report != null &&
                <DialogContent>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <p>Ilmoittaja:&nbsp;{report.reporter.userName}</p>
                            </Grid>
                            <Grid item xs={6}>
                                {phoneLink}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={this.props.classes.button}
                                    onClick={this.onClickCloseBtn}>
                                    Sulje
                                </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickRemoveMarkerBtn.bind(this, MARKER_TYPE.ILMOITUS)}>
                                Poista ilmoitus
                            </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
                }
            </Dialog>
        );
    }
}

export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(MarkerInformationDialog));