import React from 'react'
import { withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    button: {
        margin: theme.spacing.unit,
        width: "100%"
    },
    header: {
        //backgroundImage: `url(${backgroundImg})`,
       // height: '100vh',

        //filter: "blur(8px)",
        //webkitFilter: "blur(8px)",
    },
    progress: {

    },
    buttonProgress: {
        color: "green",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

// Params
// - show (bool)
// - onSubmit (function)
// - loginFailed (bool)
// - loginInProgress (bool)
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this.onClick = this.onClick.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.getForm = this.getForm.bind(this)

        this.containerStyle = {
            position: "absolute",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        };
    }
    inputChange(evt){
        const name = evt.target.name
        const value = evt.target.value
        this.setState({
            [name]: value
        })
    }
    onClick(e) {
        this.props.onSubmit(this.state.username, this.state.password)
        e.preventDefault();
        e.stopPropagation();
    }

    getForm(props){
        const classes = this.props.classes;
        return (
            <div className={classes.header}>
                <div className="container" style={this.containerStyle} >
                    <div className="row" style={{"marginTop": "50px"}}>
                        <div className="col" style={{"textAlign": "center"}}>
                            <div className="col" style={{"margin":"auto", "textAlign": "center", "backgroundColor": "white", "maxWidth": "500px", "borderRadius": "10px"}}>
                                <div style={{ "padding": "1em"}}>
                                    <form onSubmit={this.onClick}>
                                    <h2>Kirjaudu</h2><br/>
                                    <div className="form">
                                        <div className="form-group">
                                            <TextField
                                                id="username"
                                                name="username"
                                                label="Siivoojatunnus"
                                                className={classes.textField}
                                                margin="normal"
                                                onChange={this.inputChange}
                                            />
                                            <TextField
                                                id="password"
                                                name="password"
                                                label="Salasana"
                                                className={classes.textField}
                                                type="password"
                                                margin="normal"
                                                onChange={this.inputChange}
                                            />
                                        </div>
                                        <br/>
                                        {this.props.loginFailed &&
                                        <div className="alert alert-warning">Login failed</div>
                                        }
                                        <div className="form-group" style={{"position":"relative"}}>

                                            <Button variant="contained" color="primary"
                                                    //onClick={this.onClick}
                                                    type="submit"
                                                    className={classes.button}
                                                    disabled={this.props.loginInProgress}>
                                                Kirjaudu
                                            </Button>

                                            {this.props.loginInProgress &&
                                              <CircularProgress className={classes.buttonProgress} color="secondary" size={24} thickness={6.5} />
                                            }

                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                            <br/>
                            <br/>
                                <div style={{"color":"white"}}>My Ugly City&trade; &copy;&nbsp;2019 The Developers</div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        var dev = false
        var classes = ['login-container', 'login-container-show']
        if (process.env.NODE_ENV === 'development') {
            dev = true
        }
        if (this.props.isAuthenticated) {
            return null
        }
        if (!this.props.loginInProgress)
            return (
                <div className={classes.join(' ')}>
                    <this.getForm dev={dev}/>
                </div>
            )
        else
            return (
                <div className={classes.join(' ')}>
                    <fieldset disabled>
                        <this.getForm dev={dev}/>
                    </fieldset>
                </div>
            )
    }
}

export default withStyles(styles)(withTheme()(Login));