import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import * as authActions from './modules/authentication'
import * as unitActions from './modules/units'
import * as missionActions from './modules/missions'
import * as observationActions from './modules/observations'
import * as mobileActions from "./modules/mobile";
import * as positionActions from './modules/positionupdates'
import * as reportActions from './modules/reports'
import Map from './map/Map'
import Login from './Login'
import MapControls from './map/mapcontrols'
import MapLongPressActions from "./map/maplongpressactions";
import MarkerInformationDialog from './map/markerinformationdialog'
import MissionView from './map/missionview'
import SettingsView from './map/settingsview'
import CONFIG from './config'
import UnitSelect from "./unitselect/unitselect";
import PrimarySearchAppBar from './map/primarysearchappbar'
import { MARKER_TYPE } from './constants'
import Mission from './domain/mission';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.loginSubmit = this.loginSubmit.bind(this);
        this.state = {
            openMissionDialog: false,
            openObsDialog: false,
            mapCenterLatLng: {},
            follow: false,
            showMenu: false,
            showMarkerInfo: false,
            missionToShow: null,
            observationToShow: null,
            unitToShow: null,
            reportToShow: null,
            showMissionView: false,
            missionViewMission: null,
            timeRange: {start: -24, end: 0}
        }
        this.mapRef = null;
        this.setMapRef = element => {
            this.mapRef = element;
        }
    }

    unitsToFlatList = (units) => {
        let result = [];
        for (let u of units) {
            if (u.subUnits != null && u.subUnits.length > 0) {
                result = result.concat(this.unitsToFlatList (u.subUnits));
            }
            result.push(u);
        }
        return result;
    }
    loginSubmit(user, pass) {
        this.props.doLogin(user, pass);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    componentDidMount() {
        if (!this.props.authenticated && !this.props.loginInProgress) {
            this.props.CheckAuthentication();
        } else {

        }
    }
    onCenterLatLngUpdate = (latlng) => {
        this.setState({...this.state, mapCenterLatLng: latlng})
    }
    onCloseMapActions = () => {
        this.setState({...this.state, openMissionDialog: false, openObsDialog: false, openReportDialog: false});
    }
    saveNewMission = (mission) => {
        this.props.saveNewMission(mission);
    }
    saveNewObservation = (obs) => {
        this.props.saveNewObservation(obs);
    }
    saveNewReport = (report) => {
        this.props.saveNewReport(report);
    }
    onSetFollow = (follow) => {
        //console.log(follow);
        this.setState({...this.state, follow: follow});
    }
    onMapClick = () => {
        this.setState({...this.state, showMenu: !this.state.showMenu});
    }
    onNewReport = () => {
        this.setState({...this.state, openReportDialog: true});
    }
    onNewMission = () => {
        this.setState({...this.state, openMissionDialog: true});
    }
    onNewObs = () => {
        this.setState({...this.state, openObsDialog: true})
    }
    onClickUnit = (id) => {
        let toShow = null;
        let unitFlatList = this.unitsToFlatList(this.props.units);
        for(let u of unitFlatList) {
            if (u.id === id) {
                toShow = u;
                break;
            }
        }
        if (toShow != null)
            this.setState({...this.state, showMarkerInfo: true, unitToShow: toShow});
    }
    onClickObs = (id) => {
        let toShow = null;
        for(let o of this.props.observations) {
            if (o.id === id) {
                toShow = o;
                break;
            }
        }
        if (toShow != null)
            this.setState({...this.state, showMarkerInfo: true, observationToShow: toShow});
    }
    onClickMission = (id) => {
        let toShow = null;
        for(let m of this.props.missions) {
            if (m.id === id) {
                toShow = m;
                break;
            }
        }
        if (toShow != null)
            this.setState({...this.state, showMarkerInfo: true, missionToShow: toShow});
    }
    onClickReport = (id) => {
        let toShow = null;
        for(let r of this.props.reports) {
            if (r.id === id) {
                toShow = r;
                break;
            }
        }
        if (toShow != null)
            this.setState({...this.state, showMarkerInfo: true, reportToShow: toShow});
    }
    onCloseMarkerInfo = () => {
        this.setState({...this.state,
                showMarkerInfo: false,
                missionToShow: null,
                observationToShow: null,
                unitToShow: null,
                reportToShow: null});
    }
    onRemoveMarker = (type, id) => {
        console.log('onRemoveMarker, type: ' + type);

        switch(type) {
            case MARKER_TYPE.HAVAINTO:
                this.props.deleteObservation(this.state.observationToShow);
                this.setState({...this.state,
                    showMarkerInfo: false,
                    observationToShow: null});
                break;
            case MARKER_TYPE.ILMOITUS:
                this.props.deleteReport(this.state.reportToShow);
                this.setState({...this.state,
                    reportToShow: null,
                    showMarkerInfo: false});
               break;
            case MARKER_TYPE.TEHTAVA:
                if (id) {
                    let mission = new Mission();
                    mission.id = id;
                    this.props.deleteMission(mission);
                } else {
                    this.props.deleteMission(this.state.missionToShow);
                }
                this.setState({...this.state,
                    missionToShow: null,
                    showMarkerInfo: false});
               break;
        }
    }
    onMapPlus = () => {
        this.mapRef.onPlus();
    }
    onMapMinus = () => {
        this.mapRef.onMinus();
    }
    onClickActiveMission = (mission) => {
        this.setState({...this.state, showMissionView: true, missionViewMission: mission});
        this.mapRef.onSetMapCenter(mission.position);
    }
    onCloseMissionView = () => {
        this.setState({...this.state, showMissionView: false, missionViewMission: null});
    }
    onLogout = () => {
        this.props.doLogoff();
    }
    onChangeMissionStatus = (id, state, report) => {
        let mission = null;
        for(let m of this.props.missions) {
            if (m.id === id) {
                mission = m;
                break;
            }
        }
        mission.state = state;
        mission.report = report;
        this.props.updateMission(mission);
    }
    onTimeRangeChange = (range) => {
        this.setState({...this.state, timeRange: range});
    }
    findUnitRecursive = (unitId, unitlist) => {
        for(let u of unitlist) {
            if (u.id == unitId) return u;
            if (u.subUnits != null && u.subUnits.length > 0) {
                let unit = this.findUnitRecursive(unitId, u.subUnits);
                if (unit != null) return unit;
            }
        }
        return null;
    }
    addUserToUnit = (unitId) => {
        let unit = this.findUnitRecursive(unitId, this.props.units);
        if (unit == null) {
            alert("VIRHE");
            return;
        }
        unit.members.push(this.props.userProfile);
        this.props.updateUnit(unit);
    }
    changeUnitStatus = (unit, statusField, status) => {
        if (statusField === 'HE') {
            unit.statusHE = status;
        } else if (statusField === 'AJ') {
            unit.statusAJ = status;
        } else if (statusField === 'AS') {
            unit.statusAS = status;
        } else if (statusField === 'AT') {
            unit.statusAT = status;
        } else if (statusField === 'TXT') {
            unit.statusText = status;
        }
        this.props.updateUnit(unit);
    }
    ackObservation = (observation) => {
        this.props.updateObservation(observation);
    }

    onCallClick = (number) => {
        console.log('Soitellaan numeroon: ' + number );
        this.props.conferenceCall([number]);
    };

    isMobile = () => {
        return mobileActions.isMobile();
    };

    render() {
        return (
            <div>
                <PrimarySearchAppBar></PrimarySearchAppBar>
                <Map
                    mapClick={this.onMapClick}
                    observations={this.props.observations}
                    units={this.props.units}
                    missions={this.props.missions}
                    activeMission={this.state.activeMission}
                    follow={this.state.follow}
                    onCenterLatLngUpdate={this.onCenterLatLngUpdate}
                    onClickUnit={this.onClickUnit}
                    onClickObs={this.onClickObs}
                    onClickMission={this.onClickMission}
                    onClickReport={this.onClickReport}
                    setRef={this.setMapRef}
                    timeRange={this.state.timeRange}
                    positions={this.props.positions}
                    reports={this.props.reports}
                    />
                <MapControls
                    setFollow={this.onSetFollow}
                    showMenu={this.state.showMenu}
                    onNewMission={this.onNewMission}
                    onNewReport={this.onNewReport}
                    onNewObs={this.onNewObs}
                    onMapPlus={this.onMapPlus}
                    onMapMinus={this.onMapMinus}
                    missions={this.props.missions}
                    onClickActiveMission={this.onClickActiveMission}
                    onLogout={this.onLogout}
                    onTimeRangeChange={this.onTimeRangeChange}
                    userProfile={this.props.userProfile}
                    />
                <MapLongPressActions
                    openReport={this.state.openReportDialog}
                    openMission={this.state.openMissionDialog}
                    openObs={this.state.openObsDialog}
                    onClose={this.onCloseMapActions}
                    position={this.state.mapCenterLatLng}
                    saveNewMission={this.saveNewMission}
                    saveNewObservation={this.saveNewObservation}
                    saveNewReport={this.saveNewReport}
                    units={this.props.units}
                    userProfile={this.props.userProfile}
                    />
                <MarkerInformationDialog
                    show={this.state.showMarkerInfo}
                    missions={this.props.missions}
                    mission={this.state.missionToShow}
                    observation={this.state.observationToShow}
                    unit={this.state.unitToShow}
                    onCloseMarkerInfo={this.onCloseMarkerInfo}
                    onNewMission={this.onNewMission}
                    onRemoveMarker={this.onRemoveMarker}
                    changeUnitStatus={this.changeUnitStatus}
                    userProfile={this.props.userProfile}
                    report={this.state.reportToShow}
                    ackObservation={this.ackObservation}
                    onPhoneCall={this.onCallClick}
                    isMobile={this.isMobile}
                    />
                <Login
                    onSubmit={this.loginSubmit}
                    isAuthenticated={this.props.authenticated}
                    loginInProgress={this.props.loginInProgress}
                    />
                <MissionView
                    show={this.state.showMissionView}
                    mission={this.state.missionViewMission}
                    onClose={this.onCloseMissionView}
                    changeMissionStatus={this.onChangeMissionStatus}
                    />
                <SettingsView></SettingsView>

                <UnitSelect units={this.props.units}
                            isAuthenticated={this.props.authenticated}
                            loginInProgress={this.props.loginInProgress}
                            userProfile={this.props.userProfile}
                            onAddUserToUnit={this.addUserToUnit}
                    />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authenticated: state.authentication.authenticated,
    userProfile: state.authentication.userProfile,
    loginFailed: state.authentication.loginFailed,
    loginInProgress: state.authentication.loginInProgress,
    logoffInProgress: state.authentication.logoffInProgress,
    units: state.units.units,
    fetchingUnits: state.units.fetchingUnitsInProgress,
    fetchingUnitsFailed: state.units.fetchingUnitsFailed,
    missions: state.missions.missions,
    fetchingMissions: state.missions.fetchingMissionsInProgress,
    fetchingMissionsFailed: state.missions.fetchingMissionsFailed,
    observations: state.observations.observations,
    fetchingObservations: state.observations.fetchingObservationsInProgress,
    fetchingObservationsFailed: state.observations.fetchingObservationsFailed,
    mobileId: state.mobile.mobileId,
    positions: state.positionupdates.positions,
    fetchingPositions: state.positionupdates.fetchingPositionsInProgress,
    reports: state.reports.reports
})

const mapDispatchToProps = dispatch => bindActionCreators({...authActions, ...unitActions,
    ...missionActions, ...observationActions, ...mobileActions, ...positionActions, ...reportActions}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
