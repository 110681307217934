import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent';
import blue from '@material-ui/core/colors/blue';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Mission from '../domain/mission';
import Unit from '../domain/unit';
import Position from '../domain/position';
import Observation from '../domain/observation';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import User from '../domain/user';
import * as userActions  from '../modules/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

const styles = {
    heading: {
        fontSize: "1.25em",
    },
    button: {
        marginRight: "10px",
    },
    nomargin: {
        marginBottom : '0',
    },
    textField: {
        width: "100%",
    },
};



class SettingsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName : '',
            rank: '',
            unitId : 0,
            phoneNumber: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userName: nextProps.user.userName,
            rank: nextProps.user.rank,
            unitId: nextProps.user.unitId,
            phoneNumber: nextProps.user.phoneNumber
        });
    }

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.value });
    }

    handleSave = () => {
        var u = new User();
        u.id = this.props.user.id;
        u.userName = this.state.userName;        
        u.rank = this.state.rank;        
        u.phoneNumber = this.state.phoneNumber;
        this.props.updateUser(u);
        this.props.closeSettingsDialog();
    }

    render() {
        const { onClose, ...other } = this.props;
        return (
            <Dialog open={this.props.isSettingsDialogOpen} onClose={this.props.closeDialog} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">Asetukset</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <TextField
                                        id="standard-multiline-flexible"
                                        label="Sotilasarvo"
                                        value={this.state.rank}
                                        onChange={this.handleChange('rank')}
                                        className={this.props.classes.textField}
                                        margin="normal"
                                    />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                        id="standard-multiline-flexible"
                                        label="Nimi"
                                        value={this.state.userName}
                                        onChange={this.handleChange('userName')}
                                        className={this.props.classes.textField}
                                        margin="normal"
                                    />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                        id="standard-multiline-flexible"
                                        label="Puhelin"
                                        value={this.state.phoneNumber}
                                        onChange={this.handleChange('phoneNumber')}
                                        className={this.props.classes.textField}
                                        margin="normal"
                                    />
                        </Grid>                        
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={this.props.classes.button}
                                onClick={this.handleSave}>
                                Tallenna
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={this.props.classes.button}
                                onClick={this.props.closeSettingsDialog}>
                                Sulje
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    isSettingsDialogOpen: state.user.isSettingsDialogOpen,
    user: state.authentication.userProfile
})

const mapDispatchToProps = dispatch => bindActionCreators({...userActions}, dispatch)

export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps )(SettingsView)));