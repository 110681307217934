import Ajax from './ajax'
import Report from '../domain/report'

export const INSERT_REPORT = 'reports/INSERT_REPORT'
export const DELETE_REPORT = 'reports/DELETE_REPORT'

const initialState = {
    reports: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_UPDATED_reports': {
            let newReports = [];
            for (let r of action.data) {
                newReports.push(new Report(r));
            }
            return {
                ...state,
                reports: newReports
            }
        }

        default:
            return state
    }
}

export const saveNewReport = (report) => {
    return dispatch => {
        dispatch({type: INSERT_REPORT, report: report});
    }
}

export const deleteReport = (report) => {
    return dispatch => {
        dispatch({type: DELETE_REPORT, report: report});
    }
}