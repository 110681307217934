import Position from './position'
import moment from 'moment'

class PositionUpdate {
    constructor(json) {
        if (json != null) {
            this.updateTs = moment(json.updateTs);
            this.position = new Position(json.position);
            this.userId = json.userId;
        } else {

        }
    }
}

export default PositionUpdate