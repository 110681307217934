import Ajax from './ajax'
import Unit from '../domain/unit'

export const UPDATE_UNIT = 'units/UPDATE_UNIT'

const initialState = {
    units: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_UPDATED_units': {
            console.log('DATA_UPDATED_units');
            let newUnits = [];
            for (let u of action.data) {
                newUnits.push(new Unit(u));
            }
            return {
                ...state,
                units: newUnits
            }
        }

        default:
            return state
    }
}

export const updateUnit = (unit) => {
    return dispatch => {
        dispatch({type: UPDATE_UNIT, unit: unit});
    }
}