export const ADD_ALERT = 'appstate/ADD_ALERT'
export const REMOVE_ALERT = 'appstate/REMOVE_ALERT'

const initialState = {
    openAlerts: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALERT: {
            let newAlerts = state.openAlerts.slice();
            newAlerts.push({
                id: (new Date()).getTime(),
                type: action.alertType,
                headline: action.heading,
                message: action.message,
                timeout: action.timeout
            });
            return {
                ...state,
                openAlerts: newAlerts
            };
        }
        case REMOVE_ALERT: {
            let newAlerts = state.openAlerts.slice();
            const idx = newAlerts.indexOf(action.alert);
            newAlerts = [...newAlerts.slice(0, idx), ...newAlerts.slice(idx +1)];
            if (idx >= 0) {
                return {
                    ...state,
                    openAlerts: newAlerts
                }
            }
            break;
        }
        default:
            return state
    }
}



export const addAlert = (type, heading, message, timeout) => {
    return dispatch => {
        dispatch({
            type: ADD_ALERT,
            alertType: type,
            heading: heading,
            message: message,
            timeout: timeout
        });
    };
}

export const removeAlert = (alert) => {
    return dispatch => {
        dispatch({
            type: REMOVE_ALERT,
            alert: alert
        });
    }
}