import moment from 'moment'
import Position from './position'
import User from './user'
import {UNIT_STATUS} from './../constants'

class Unit {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            if (json.position != null)
                this.position = new Position(json.position);
            else
                this.position = null;
            this.active = json.active;
            this.members = [];
            if (json.members != null) {
                for(let m of json.members) {
                    this.members.push(new User(m));
                }
            }

            this.subUnits = [];
            if (json.subUnits != null) {
                for(let u of json.subUnits) {
                    this.subUnits.push(new Unit(u));
                }
            }
            this.leader = new User(json.leader);
            this.latestUpdate = moment(json.latestUpdate);
            this.statusText = json.statusText;
            this.statusHE = json.statusHE;
            this.statusAJ = json.statusAJ;
            this.statusAT = json.statusAT;
            this.statusAS = json.statusAS;
        } else {
            this.id = 0;
            this.name = '';
            this.position = null;
            this.active = false;
            this.members = [];
            this.subUnits = [];
            this.leader = '';
            this.latestUpdate = moment();
            this.statusText = '';
            this.statusHE = UNIT_STATUS.OK;
            this.statusAJ = UNIT_STATUS.OK;
            this.statusAT = UNIT_STATUS.OK;
            this.statusAS = UNIT_STATUS.OK;
        }
    }
    status() {
        var status = UNIT_STATUS.OK;
    
        if (this.statusHE === UNIT_STATUS.CRIT || this.statusAT === UNIT_STATUS.CRIT || this.statusAJ === UNIT_STATUS.CRIT || this.statusAS === UNIT_STATUS.CRIT) {
            status = UNIT_STATUS.CRIT;
        } else if (this.statusHE === UNIT_STATUS.WARN || this.statusAT === UNIT_STATUS.WARN || this.statusAJ === UNIT_STATUS.WARN || this.statusAS === UNIT_STATUS.WARN) {
            status = UNIT_STATUS.WARN;
        }
    
        return status;
    }
    
}

export default Unit;