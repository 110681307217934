import React from 'react';
import ReactDOM from 'react-dom'
import moment from 'moment'
import {UNIT_STATUS} from './../constants';
import {bindActionCreators} from "redux";
import * as positionActions from "../modules/positionupdates";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Position from '../domain/position';

const baselineLat = Number(63.450960156371416);
const baselineLng = Number(26.0702711493042);


function sortObsByTimestamp(a, b) {
    if (moment(a.firstObserved).isBefore(moment(b.firstObserved))) {
        return -1;
    }
    return 1;
}

class Map extends React.Component {

    constructor(props) {
        super(props);
        this.onLocationFound = this.onLocationFound.bind(this);
        this.addUnitMarker = this.addUnitMarker.bind(this);
        this.addObsMarker = this.addObsMarker.bind(this);
        this.onPlus = this.onPlus.bind(this);
        this.state = {
            map: null,
            currLocMarker: null,
            currLoc: null,
            crossHair: null,
            firstPosSet: false,
            firstLat: 0,
            firstLng: 0,
            actualLat: 0,
            actualLng: 0
        };

        this.unitMarkers = [];
        this.obsMarkers = [];
        this.missionMarkers = [];
        this.reportMarkers = [];
        this.memberMarkers = [];
    }

    onPlus() {
        this.state.map.zoomIn(1);
    }
    onMinus = () => {
        this.state.map.zoomOut(1);
    }
    onSetMapCenter = (position) => {
        this.state.map.panTo([position.latitude, position.longitude]);
    }
    onMove = (e) => {
        const L = window.L;
        let crossHair = this.state.crossHair;
        if (crossHair == null) {
            let icon = L.icon({
                iconUrl: 'images/crosshair.png',
                iconSize: [80,80],
                iconAnchor: [40,40]
            });
            crossHair = L.marker(this.state.map.getCenter(), {icon: icon}).addTo(this.state.map);
            this.setState({...this.state, crossHair: crossHair});
        } else {
            crossHair.setLatLng(this.state.map.getCenter());
        }
        this.props.onCenterLatLngUpdate(this.state.map.getCenter());
    }
    onClick = (e) => {
        const _this = this;
        this.props.mapClick();

        if (document.onclick == null) {
            setTimeout(() => {
                _this.state.map.off('click');
                document.onclick = _this.onClick;
            },1);

        } else {
            setTimeout(() => {
                _this.state.map.on('click', _this.onClick);
                document.onclick = null;
            },1);
        }
    }

    onLocationFound(loc) {
        if (loc.latlng.lat == null || this.state.map == null) return;

        let currLat=0;
        let currLng=0;
        let latlng = loc.latlng;
        if (!this.state.firstPosSet) {
            this.setState({...this.state, firstPosSet: true, firstLat: Number(loc.latlng.lat), firstLng: Number(loc.latlng.lng)});
            currLat = baselineLat;
            currLng = baselineLng;
            latlng.lat = currLat;
            latlng.lng = currLng;
        } else {
            currLat = baselineLat + Number(loc.latlng.lat) - this.state.firstLat;
            currLng = baselineLng + Number(loc.latlng.lng) - this.state.firstLng;
            latlng.lat = currLat;
            latlng.lng = currLng;
        }
        
        // Location update
        // - screen alive -> always web
        // - screen off -> native updates

        let position = new Position();
        position.latitude = currLat;
        position.longitude = currLng;
        this.props.updatePosition(this.props.userProfile.id, position);

        const L = window.L;
        const radius = loc.accuracy / 2;

        if (this.state.currLocMarker != null)
            this.state.map.removeLayer(this.state.currLocMarker);

        console.log(latlng);
        let newMarker = L.circle(latlng, radius);
        newMarker.addTo(this.state.map);

        if (this.props.follow)
            this.state.map.setView(latlng, this.state.map.getZoom());

        this.setState({...this.state, 
            currLocMarker: newMarker, 
            actualLat: loc.latlng.lat, 
            actualLng: loc.latlng.lng,
            currLoc: latlng
        });
    }

    onClickUnit = (e, id) => {
        this.props.onClickUnit(id);
    }
    onClickObs = (e, id) => {
        this.props.onClickObs(id);
    }
    onClickMission = (e, id) => {
        this.props.onClickMission(id);
    }
    onClickReport = (e, id) => {
        this.props.onClickReport(id);
    }
    addUnitMarker(unit) {
        const L = window.L;
        // TODO: var myIcon = L.divIcon({className: 'my-div-icon'});
        let imgUrl = '';
        switch(unit.status()) {
            case UNIT_STATUS.OK:
                imgUrl = "images/unit_ok.png";
                break;
            case UNIT_STATUS.WARN:
                imgUrl = "images/unit_warning.png";
                break;
            case UNIT_STATUS.CRIT:
                imgUrl = "images/unit_error.png";
                break;
        }
        //const imgStyle = "transform: rotate(" + unit.position.course + "deg);";
        var icon = L.divIcon({
            className: 'unit-directed-90',
            html: '<img src="' + imgUrl + '"/>',
            iconSize: [120,120]
        });
        const lat = unit.position.latitude;
        const lng = unit.position.longitude;
        //let marker = L.marker([lat, lng]).addTo(this.state.map);
        let marker = L.marker([lat, lng], {icon: icon}).addTo(this.state.map);
        marker.on('click', (e) => this.onClickUnit(e, unit.id));
        this.unitMarkers.push(marker);
    }

    addObsMarker(obs) {
        const L = window.L;
        const lat = obs.position.latitude;
        const lng = obs.position.longitude;
        const iconUrl = obs.ack ? 'images/marker-icon-ack.png' : 'images/marker-icon-alert.png';
        let marker = L.marker([lat, lng],
            {icon: L.icon({
                    iconUrl: iconUrl,
                    iconSize: [50,50],
                    iconAnchor: [25, 49]
                })}
        ).addTo(this.state.map);
        marker.on('click', (e) => this.onClickObs(e, obs.id));
        this.obsMarkers.push(marker);
    }
    addReportMarker = (report) => {
        const L = window.L;
        const lat = report.position.latitude;
        const lng = report.position.longitude;
        const imgUrl = "images/warning.png";

        var icon = L.divIcon({
            className: 'immediate-report-icon',
            html: '<img src="' + imgUrl + '"/>',
            iconSize: [60,60]
        });

        let marker = L.marker([lat, lng], {icon: icon}).addTo(this.state.map);
        marker.on('click', (e) => this.onClickReport(e, report.id));
        this.reportMarkers.push(marker);
    }
    addMissionMarker = (m) => {
        const L = window.L;
        const lat = m.position.latitude;
        const lng = m.position.longitude;
        let icon = null;
        if (m.state !== "v") {
            icon = L.divIcon({
                className: 'mission-icon-css',
                iconSize: new L.Point(25, 25),
            });
        } else {
            icon = L.divIcon({
                className: 'mission-icon-css-ready',
                iconSize: new L.Point(25, 25),
            })
        }
        let marker = L.marker([lat, lng],
            {
                icon: icon
            }).addTo(this.state.map);
        marker.on('click', (e) => this.onClickMission(e, m.id));
        this.missionMarkers.push(marker);
    }

    clearMarkers = (array) => {
        for(let m of array) {
            this.state.map.removeLayer(m);
        }
    }

    unitsToFlatList = (units) => {
        let result = [];
        for (let u of units) {
            if (u.subUnits != null && u.subUnits.length > 0) {
                result = result.concat(this.unitsToFlatList (u.subUnits));
            }
            result.push(u);
        }
        return result;
    }
    determineUnitPositions = (units, userPositions) => {
        for(let u of units) {
            if (userPositions[u.leader.id] != null && userPositions[u.leader.id].length > 0) {
                u.position = userPositions[u.leader.id][0].position;
            } else {
                console.log("No position events for unit leader!");
                u.position = null;
            }
        }
        return units;
    }
    isMemberOrLeaderOfUnit = (unit, userId) => {
        if (unit.leader.id == userId) {
            return true;
        }
        if (unit.members != null && unit.members.length > 0) {
            for(let m of unit.members) {
                if (m.id == this.props.userProfile.id) {
                    return true;
                }
            }
        }
        return false;
    }
    addUnitMemberMarker = (member) => {
        const L = window.L;
        const lat = member.position.latitude;
        const lng = member.position.longitude;
        const imgUrl = "images/grunt.png";

        var icon = L.divIcon({
            className: '',
            html: '<span style="position: absolute; font-weight: bold;top:-10px;">'+member.userName+'</span><img src="' + imgUrl + '"/>',
            iconSize: [35,35]
        });

        let marker = L.marker([lat, lng], {icon: icon}).addTo(this.state.map);
        //marker.on('click', (e) => this.onClickReport(e, report.id));
        this.memberMarkers.push(marker);
    }
    determineMemberPosition = (member, positions) => {
        if (positions[member.id] != null && positions[member.id].length > 0) {
            member.position = positions[member.id][0].position;
            return member;
        }
        member.position = null;
        return member;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const L = window.L;
        if (this.obsMarkers.length > 0) {
            this.clearMarkers(this.obsMarkers)
            this.obsMarkers = [];
        }
        if (this.unitMarkers.length > 0) {
            this.clearMarkers(this.unitMarkers);
            this.unitMarkers = [];
        }
        if (this.memberMarkers.length > 0) {
            this.clearMarkers(this.memberMarkers);
            this.memberMarkers = [];
        }
        if (this.missionMarkers.length > 0) {
            this.clearMarkers(this.missionMarkers);
            this.missionMarkers = [];
        }
        if (this.reportMarkers.length > 0) {
            this.clearMarkers(this.reportMarkers);
            this.reportMarkers = [];
        }
        if (this.props.units != null && this.props.units.length > 0 &&
            this.props.positions != null) {
            let unitFlatList = this.unitsToFlatList(this.props.units);
            unitFlatList = this.determineUnitPositions(unitFlatList, this.props.positions);
            for(let u of unitFlatList) {
                if (u.position != null && u.position.latitude != null && u.position.longitude != null) {
                    this.addUnitMarker(u);
                    if (this.isMemberOrLeaderOfUnit(u, this.props.userProfile.id) && u.members != null && u.members.length > 0) {
                        for(let m of u.members) {
                            let member = this.determineMemberPosition(m, this.props.positions);
                            if (member.position != null) {
                                this.addUnitMemberMarker(member);
                            }
                        }
                    }
                }
            }
        }
        if (this.props.observations != null && this.props.observations.length > 0) {
            // filter observations, hack
            if (this.props.timeRange.start > -24 || this.props.timeRange.end < 0) {
                let temp = this.props.observations;
                temp.sort(sortObsByTimestamp);
                const toSkipStart = parseInt((24 - Math.abs(this.props.timeRange.start) * this.props.observations.length)/24);
                const toSkipEnd = parseInt(((Math.abs(this.props.timeRange.end)) * this.props.observations.length)/24);
                const observations = temp.slice(toSkipStart, this.props.observations.length - toSkipEnd);
                for(let o of observations) {
                    if (o.position != null) {
                        this.addObsMarker(o);
                    }
                }
            } else {
                for(let o of this.props.observations) {
                    if (o.position != null) {
                        this.addObsMarker(o);
                    }
                }
            }
        }
        if (this.props.missions != null && this.props.missions.length > 0) {
            for(let m of this.props.missions) {
                if (m.position != null) {
                    this.addMissionMarker(m);
                }
            }
        }
        if (this.props.reports != null && this.props.reports.length > 0) {
            for (let r of this.props.reports) {
                if (r.position != null) {
                    this.addReportMarker(r);
                }
            }
        }
        if (this.props.follow !== prevProps.follow && this.props.follow &&
            this.state.currLoc != null && this.state.map != null)
            this.state.map.setView(this.state.currLoc, this.state.map.getZoom());
    }

    componentDidMount() {
        this.props.setRef(this);
        const L = window.L;

        L.Map.mergeOptions({
            touchExtend: true
        });

        L.Map.TouchExtend = L.Handler.extend({
            initialize: function (map) {
                this._map = map;
                this._container = map._container;
                this._pane = map._panes.overlayPane;
            },

            addHooks: function () {
                L.DomEvent.on(this._container, 'touchstart', this._onTouchStart, this)
                L.DomEvent.on(this._container, 'touchend', this._onTouchEnd, this)
                L.DomEvent.on(this._container, 'touchmove', this._onTouchMove, this)
            },

            removeHooks: function () {
                L.DomEvent.off(this._container, 'touchstart', this._onTouchStart)
                L.DomEvent.off(this._container, 'touchend', this._onTouchEnd)
                L.DomEvent.off(this._container, 'touchmove', this._onTouchMove)
            },

            _onTouchEvent: function (e, type) {
                if (!this._map._loaded) { return; }

                let touch = e.touches[0];

                let containerPoint = L.point(touch.clientX, touch.clientY);
                let layerPoint = this._map.containerPointToLayerPoint(containerPoint);
                let latlng = this._map.layerPointToLatLng(layerPoint);

                this._map.fire(type, {
                    latlng: latlng,
                    layerPoint: layerPoint,
                    containerPoint: containerPoint,
                    originalEvent: e
                });

            },
            _onTouchStart: function(e)  {
                this._onTouchEvent(e, 'touchstart');
            },
            _onTouchEnd: function(e) {
                if (!this._map._loaded) { return; }
                this._map.fire('touchend', {
                    originalEvent: e
                });
            },
            _onTouchMove: function(e) {
                this._onTouchEvent(e, 'touchmove');
            },
        });

        L.Map.addInitHook('addHandler', 'touchExtend', L.Map.TouchExtend);

        const crs = new L.Proj.CRS.TMS('EPSG:3067',
            '+proj=utm +zone=35 +ellps=GRS80 +units=m +towgs84=0,0,0,-0,-0,-0,0 +no_defs',
            [-548576.0, 6291456.0, 1548576.0, 8388608],
            {
                resolutions: [
                    8192,
                    4096,
                    2048,
                    1024,
                    512,
                    256,
                    128,
                    64,
                    32,
                    16,
                    8,
                    4,
                    2,
                    1,
                    0.5,
                    0.25,
                    0.125
                ]
            });
        const map = new L.Map(ReactDOM.findDOMNode(this), {
            crs: crs,
            continuousWorld: true,
            worldCopyJump: false,
            zoomControl: false
        });

        let tileUrl = 'https://{s}.kartat.kapsi.fi/mapcache/peruskartta_3067/{z}/{x}/{y}.png';

        const peruskarttatilelayer = new L.Proj.TileLayer.TMS(tileUrl, crs, {
            maxZoom: 16,
            minZoom: 0,
            tileSize: 256,
            tms: false,
            continuousWorld: true,
            attribution: '',
            subdomains: ['tiles']
        });



        tileUrl = 'https://{s}.kartat.kapsi.fi/mapcache/taustakartta_3067/{z}/{x}/{y}.png'
        let taustakarttatilelayer = new L.Proj.TileLayer.TMS(tileUrl, crs, {
                maxZoom: 16,
                minZoom: 0,
                tileSize: 256,
                tms: false,
                continuousWorld: true,
                attribution: '',
                subdomains: ['tiles']
            });
        map.addLayer(taustakarttatilelayer);

        tileUrl = 'https://{s}.kartat.kapsi.fi/mapcache/kiinteistorajat_3067/{z}/{x}/{y}.png';
        let kiinteistorajatilelayer = new L.Proj.TileLayer.TMS(tileUrl, crs, {
                maxZoom: 16,
                minZoom: 10,
                tileSize: 256,
                tms: false,
                continuousWorld: true,
                attribution: '',
                subdomains: ['tiles']
            });

        tileUrl = 'https://{s}.kartat.kapsi.fi/mapcache/palstatunnus_3067/{z}/{x}/{y}.png';
        let palstatunnustilelayer = new L.Proj.TileLayer.TMS(tileUrl, crs, {
                maxZoom: 16,
                minZoom: 12,
                tileSize: 256,
                tms: false,
                continuousWorld: true,
                attribution: '',
                subdomains: ['tiles']
            });


        const baseMaps = {

            "taustakartta": taustakarttatilelayer,
            "peruskartta": peruskarttatilelayer,
        }

        const overlayMaps = {
            "kiinteistörajat": kiinteistorajatilelayer,
            "Palstatunnukset": palstatunnustilelayer,
        }
        L.control.layers(baseMaps, overlayMaps).addTo(map);

        map.setView([65.00908351846371, 25.47330319728891], 11);
        map.locate({watch: true});


        let icon = L.icon({
            iconUrl: 'images/crosshair.png',
            iconSize: [80,80],
            iconAnchor: [40,40]
        });
        let crossHair = L.marker(map.getCenter(), {icon: icon}).addTo(map);
        this.props.onCenterLatLngUpdate(map.getCenter());

        // hack, force resize event to get map drawn properly. Dunno why.
        window.resizeTo(0,0);

        map.on('locationfound', this.onLocationFound);
        //map.on('mousedown', this.onMouseDown);
        //map.on('mouseup', this.onMouseUp);
        map.on('move', this.onMove);
        //map.on('movestart', this.onMoveStart);
        //map.on('moveend', this.onMoveEnd);
        //map.on('touchstart', this.onTouchStart);
        //map.on('touchend', this.onTouchEnd);
        //map.on('touchmove', this.onTouchMove);
        map.on('click', this.onClick);

        this.setState({...this.state, crossHair: crossHair, map: map});
    }

    render() {
        return (
            <div className="map"></div>
        );
    }
}

const mapStateToProps = state => ({
    userProfile: state.authentication.userProfile
})
const mapDispatchToProps = dispatch => bindActionCreators({...positionActions}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Map))
