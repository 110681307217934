import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Slider } from 'antd';
import User from '../domain/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as userActions from '../modules/user'

const styles = {
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    fab: {

    },
    root: {
        flexGrow: 1,
        padding: "5px"
    },
    follow: {
        position: "fixed",
        left: "10px",
        bottom: "140px",
        width: "50px",
        paddingLeft: "7px",
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow: "0 1px 7px rgba(0,0,0,0.65)",
    },
    followselected: {
        position: "fixed",
        left: "10px",
        bottom: "140px",
        width: "50px",
        paddingLeft: "7px",
        backgroundColor: "lightgray !important",
        zIndex: 1001,
        borderRadius: "5px",
        boxShadow: "0 1px 7px rgba(0,0,0,0.65)",
    },
    zoomcontrolframe: {
        position: "fixed",
        left: "10px",
        bottom: "10px",
        backgroundColor: "white",
        boxShadow: "0 1px 7px rgba(0,0,0,0.65)",
        borderRadius: "4px",
        font: "bold 28px 'Lucida Console', Monaco, monospace",
    },
    zoomcontrolplus: {
        width: "50px",
        height: "50px",
        display: "block",
        textAlign: "center",
        //padding: "11px",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        lineHeight: "50px",
        cursor: "pointer",
        borderBottom: "1px solid #ccc",
        borderTopRightRadius: "4px",
        borderTopRightRadius: "4px",
    },
    zoomcontrolminus: {
        width: "50px",
        height: "50px",
        display: "block",
        textAlign: "center",
        //padding: "11px",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        lineHeight: "50px",
        cursor: "pointer",
        borderBottomRightRadius: "4px",
        borderBottomRightRadius: "4px",
    },
    missionindicator: {
        position: "fixed",
        left: "10px",
        bottom: "200px",
        padding: "15px",
        backgroundColor: "rgba(255,255,255,0.5)",
        boxShadow: "0 1px 7px rgba(0,0,0,0.65)",
        borderRadius: "4px",
        font: "bold 28px 'Lucida Console', Monaco, monospace",
        color: "lightgray",
    },
    missionindicatoractive: {
        color: "black",
        backgroundColor: "rgba(255,255,255,1)",
    },
    settingsindicator: {
        position: "fixed",
        left: "10px",
        bottom: "280px",
        padding: "15px",
        backgroundColor: "rgba(255,255,255,0.5)",
        boxShadow: "0 1px 7px rgba(0,0,0,0.65)",
        borderRadius: "4px",
        font: "bold 28px 'Lucida Console', Monaco, monospace",
        color: "lightgray",
    },
    settingsindicatoractive: {
        color: "black",
        backgroundColor: "rgba(255,255,255,1)",
    }, 
    logout: {
        position: "fixed",
        left: "10px",
        top: "10px",
        opacity: "0.75",
    },
    slidercontainer: {
        position: "fixed",
        left: "25%",
        width: "50%",
        background: "rgba(220,220,220,0.5)",
        paddingLeft: "10px",
        paddingRight: "10px",
    }
};

function sliderTipFormatter(value) {
    return `${value} h`;
}

class MapControls extends React.Component {
    constructor(props) {
        super(props);
        this.toggleFollow = this.toggleFollow.bind(this);
        this.state = {
            follow: false,
            sliderValue: {
                start: -24,
                end: 0
            }
        };
    }
    toggleFollow() {
        this.setState({...this.state, follow: !this.state.follow});
        this.props.setFollow(!this.state.follow);
    }
    onReport = () => {
        this.props.onNewReport();
    }
    onNewMission = () => {
        this.props.onNewMission();
    }
    onNewObs = () => {
        this.props.onNewObs();
    }
    onClickMission = () => {
        this.props.onClickActiveMission(this.myActiveMission());
    }
    onLogout = () => {
        this.props.onLogout();
    }
    onSliderChange = (e) => {
        const newValue = {start: e[0], end: e[1]};
        this.setState({...this.state, sliderValue: newValue});
        this.props.onTimeRangeChange(newValue);
    }
    myActiveMission = () => {
        if (this.props.missions != null && this.props.missions.length > 0) {
            for(let m of this.props.missions) {
                if (m.responsible.id == this.props.userProfile.id) {
                    return m;
                }
            }
        }
        return null;
    }
    render() {
        var missionStyle = {};
        if(this.myActiveMission() !== null) {
            if(this.myActiveMission().state === 'u') {
                missionStyle = {
                    animation: 'blink 1s steps(5, start) infinite',
                    backgroundColor: 'red'
                };
            }
        }
        return (
            <div>
                {this.state.follow &&
                <div
                    role="button"
                    className={this.props.classes.followselected}
                    title="Seuraa"
                    onClick={this.toggleFollow}
                    >
                    <img src="images/baseline_navigation_black_18dp.png"/>
                </div>
                }
                {!this.state.follow &&
                <div
                    role="button"
                    className={this.props.classes.follow}
                    title="Seuraa"
                    onClick={this.toggleFollow}
                    >
                    <img src="images/outline_navigation_black_18dp.png"/>
                </div>
                }

                <div className={this.props.classes.zoomcontrolframe}>
                    <a className={this.props.classes.zoomcontrolplus} onClick={this.props.onMapPlus}>+</a>
                    <a className={this.props.classes.zoomcontrolminus} onClick={this.props.onMapMinus}>-</a>
                </div>

                {!this.myActiveMission() &&
                <div className={this.props.classes.missionindicator}>
                    -
                </div>
                }
                {this.myActiveMission() &&
                <div style={missionStyle} title="Tehtävä" role="button" onClick={this.onClickMission}
                     className={`${this.props.classes.missionindicator} ${this.props.classes.missionindicatoractive}`}>
                    {this.myActiveMission().state}
                </div>
                }
                <Drawer
                    anchor="bottom"
                    open={this.props.showMenu}
                    onClose={this.toggleDrawer}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer}
                        onKeyDown={this.toggleDrawer}>
                        <Grid container className={this.props.classes.root} spacing={24}>
                            <Grid item xs={12}>
                                <Grid container className={this.props.classes.demo} justify="center" spacing={24}>
                                    <Grid item>
                                        <Button color="primary" onClick={this.onNewMission}>
                                            Tehtävä
                                        </Button>
                                        <Button color="primary" onClick={this.onNewObs}>
                                            Havainto
                                        </Button>
                                        <Button color="primary" onClick={this.onReport}>
                                            Ilmoitus
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>

                <div className={this.props.classes.slidercontainer}>
                    <Slider range tooltipVisible
                            defaultValue={[-24, 0]}
                            disabled={false}
                            onChange={this.onSliderChange}
                            tipFormatter={sliderTipFormatter}
                            min={-24}
                            max={0}/>
                </div>

            </div>);
    }

}

MapControls.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({...userActions}, dispatch)

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps )(MapControls));  