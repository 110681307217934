import Position from './position'
import User from './user'

class Mission {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.position = new Position(json.position);
            this.status = json.status;
            this.task = json.task;
            this.troops = json.troops;
            this.additionalInformation = json.additionalInformation;
            this.responsible = new User(json.responsible);
            this.state = json.state;
            this.report = json.report;
        } else {
            this.id = Math.floor(Math.random() * 1000000) + 1;
            this.position = new Position();
            this.status = '';
            this.task = '';
            this.troops = '';
            this.additionalInformation = '';
            this.responsible = new User();
            this.state = '';
            this.report = '';
        }
    }
}

export default Mission;