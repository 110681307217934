import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel
} from '@aspnet/signalr'; // version 1.0.4

import CONFIG from "../config";
import * as mobile from '../modules/mobile';
// action for user authentication and receiving the access_token
import { LOGIN_AUTHENTICATED } from '../modules/authentication'
import { UPDATE_UNIT } from '../modules/units'
import { UPDATE_MISSION, SAVE_NEW_MISSION, DELETE_MISSION } from '../modules/missions'
import {INSERT_OBSERVATION, DELETE_OBSERVATION, UPDATE_OBSERVATION} from "../modules/observations";
import { UPDATE_MOBILE_ID, SEND_MESSAGE } from '../modules/mobile';
import { UPDATE_POSITION } from "../modules/positionupdates";
import { INSERT_REPORT, DELETE_REPORT } from "../modules/reports";
import * as userActions from '../modules/user';
import User from '../domain/user';
import * as authenticationActions from "../modules/authentication";


var connection;
var connectionUp = false;

const connectionAlive = () => {
    return connection && connectionUp;
}

const startSignalRConnection = (connection, dispatch) => connection.start()
    .then(() => {
        console.info('SignalR Connected');
        connectionUp = true;
        dispatch(mobile.getMobileId());
    })
    .catch(err => {
        console.error('SignalR Connection Error: ', err);
        connectionUp = false;
        setTimeout(startSignalRConnection(connection, dispatch), 5000);
    });

const signalRMiddleware = getState => next => async (action) => {
    //console.log('Middleware action' + action.type); 
    switch(action.type) {
        case LOGIN_AUTHENTICATED: {
            const connectionHub = CONFIG.backendUrl + "testhub";
            const protocol = new JsonHubProtocol();

            // let transport to fall back to to LongPolling if it needs to
            const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

            const options = {
                transport,
                logMessageContent: true,
                logger: LogLevel.Trace,
                accessTokenFactory: () => {"121212121212"}
            };

            // create the connection instance
            connection = new HubConnectionBuilder()
                .withUrl(connectionHub, options)
                .withHubProtocol(protocol)
                .build();

            connection.on('UpdateData', (what, data) => getState.dispatch({type: "DATA_UPDATED_"+what, data: data}));
            connection.on('UserUpdated', function () {
                console.log('checkauthentication');
                authenticationActions.CheckAuthentication()(getState.dispatch);
            });

            // re-establish the connection if connection dropped
            connection.onclose(() => {
                connectionUp = false;
                setTimeout(startSignalRConnection(connection, getState.dispatch), 5000)
            });

            startSignalRConnection(connection, getState.dispatch);
            break;
        }
        case UPDATE_UNIT: {
            if (!connectionAlive()) break;
            connection.invoke("UpdateUnit", action.unit);
            break;
        }
        case SAVE_NEW_MISSION: {
            if (!connectionAlive()) break;
            connection.invoke("InsertMission", action.mission);
            break;
        }
        case UPDATE_MISSION: {
            if (!connectionAlive()) break;
            connection.invoke("UpdateMission", action.mission);
            break;
        }
        case DELETE_MISSION: {
            if (!connectionAlive()) break;
            connection.invoke("DeleteMission", action.mission);
            break;
        }
        case INSERT_OBSERVATION: {
            if (!connectionAlive()) break;
            connection.invoke("InsertObservation", action.observation);
            break;
        }
        case DELETE_OBSERVATION: {
            if (!connectionAlive()) break;
            connection.invoke("DeleteObservation", action.observation);
            break;
        }
        case UPDATE_MOBILE_ID: {
            if (!connectionAlive()) break;
            const user = getState.getState().authentication.userProfile;
            if (user != null) {
                console.log(action.mobileId, user.id);
                connection.invoke("UpdateMobileId", user.id, action.mobileId);
            }
            break;
        }
        case UPDATE_POSITION: {
            if (!connectionAlive()) break;
            connection.invoke("UpdatePosition", action.userId, action.position);
            break;
        }
        case SEND_MESSAGE: {
            if (!connectionAlive()) break;
            console.log("SEND_MESSAGE");
            console.log(action.title, action.text, action.mobileIds);
            connection.invoke("SendMessage", action.title, action.text, action.mobileIds);
            break;
        }
        case INSERT_REPORT: {
            if (!connectionAlive()) break;
            connection.invoke("InsertReport", action.report);
            break;
        }
        case userActions.UPDATE_USER: {
            console.log('signalr middleware user.update_user')
            if(!connection) break;
            var user = new User()
            user.id = action.user.id;
            user.userName = action.user.userName;
            user.phoneNumber = action.user.phoneNumber;
            user.rank = action.user.rank;
            connection.invoke("UpdateUser", user);
            break;
        }
        case DELETE_REPORT: {
            if (!connectionAlive()) break;
            connection.invoke("DeleteReport", action.report);
            break;
        }
        case UPDATE_OBSERVATION: {
            if (!connectionAlive()) break;
            connection.invoke("UpdateObservation", action.observation);
            break;
        }
    }
    return next(action);
};

export default signalRMiddleware;