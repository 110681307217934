
class User {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.userName = json.username;
            this.rank = json.rank;
            this.unitId = json.unitId;
            this.phoneNumber = json.phoneNumber;
            this.roles = [];
            this.roles.push("User");
        } else {
            this.id = 0;
            this.userName = '';
            this.rank = '';
            this.unitId = 0;
            this.phoneNumber = "";
            this.roles = [];
        }
    }
}

export default User;