import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { MARKER_TYPE } from '../../constants';
import CONFIG from '../../config';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    heading: {
        fontSize: "1.25em",
    },
    button: {
    },
    nomargin: {
        marginBottom : '0',
    }
});

class ObservationDialogContent extends React.Component {
    constructor(props) {
        super(props);
    };

    handleClose = () => {
        this.props.onCloseMarkerInfo();
    };

    onClickCloseBtn = () => {
        this.props.onCloseMarkerInfo();
    };
    onClickImage = () => {
        window.open(CONFIG.backendUrl + "api/img/" + this.props.observation.imgId);
    }
    render() {
        const { onClose, ...other } = this.props;
        const observation = this.props.observation;
        const userProfile = this.props.userProfile;

        if (observation === null) {
            return null;
        }

        let position = observation.position.latitude + ", " + observation.position.longitude;
        let title = "Havainto: ";
        let phoneLink = (<a href={'tel:'+observation.reporter.phoneNumber}>
            <img src='images/luuri_gre.png' width='48' height='48' onClick={this.props.onCallClick.bind(this, observation.reporter.phoneNumber)}/></a>);
        if (this.props.isMobile()) {
            phoneLink = (<img src='images/luuri_gre.png' width='48' height='48' onClick={this.props.onCallClick.bind(this, observation.reporter.phoneNumber)}/>);
        }

        return (
            <DialogContent>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <em>'{this.props.observation.description}'</em>
                        </Grid>
                        <Grid item xs={12}>
                            Havaintotyyppi: {observation.type}
                        </Grid>
                        <Grid item xs={12}>
                            Havaintosäde: {observation.radius}m
                        </Grid>
                        {this.props.observation.imgId != null && this.props.observation.imgId.length > 0 &&
                        <Grid item xs={12}>
                            <img src={CONFIG.backendUrl + "api/img/" + this.props.observation.imgId} height="150" onClick={this.onClickImage}/>
                        </Grid>
                        }
                        <Grid item xs={6}>
                            <p>Ilmoittaja:&nbsp;{observation.reporter.userName}</p>
                        </Grid>
                        <Grid item xs={6}>
                            {phoneLink}
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickCloseBtn}>
                                Sulje
                            </Button>
                            {userProfile.id === observation.reporter.id &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.props.onClickRemoveMarkerBtn.bind(this, MARKER_TYPE.HAVAINTO)}>
                                Poista havainto
                            </Button>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </DialogContent>
);
    }
}

export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(ObservationDialogContent));