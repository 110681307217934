
let CONFIG = {
    backendUrl: "",
}

if (process.env.NODE_ENV === 'development') {
    CONFIG.backendUrl = "http://localhost:5000/";
} else {
    CONFIG.backendUrl = "https://api.myuglycity.com/";
}


export default CONFIG