export const UNIT_STATUS = {
    OK: 1,
    WARN: 2,
    CRIT: 3
};

export const REPORT_TYPE = {
    CONTACT: 'Viholliskosketus',
    OBSERVATION: 'Vihollishavainto',
    M_USAGE: 'Tväl käyttö',
    INDIRECT: 'Epäsuora tuli',
};

export const OBS_TYPE = {
    TULIPALO: 'Tulipalo',
    RAJAHDYS: 'Räjähdys',
    ILMAVAARA: 'Ilmavaara',
    ILMAHALYTYS: 'Ilmahalytys',
    YLEINEN: 'Yleinen'
};

export const MARKER_TYPE = {
    HAVAINTO: 'OBS',
    ILMOITUS: 'ALERT',
    TEHTAVA: 'MISSION'
};