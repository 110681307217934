import Ajax from './ajax'
import Observation from '../domain/observation'

export const INSERT_OBSERVATION = 'observation/INSERT_OBSERVATION'
export const DELETE_OBSERVATION = 'observation/DELETE_OBSERVATION'
export const UPDATE_OBSERVATION = 'observation/UPDATE_OBSERVATION'

const initialState = {
    observations: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_UPDATED_observations': {
            let newObs = [];
            for (let o of action.data) {
                newObs.push(new Observation(o));
            }
            return {
                ...state,
                observations: newObs,
                fetchingObservationsInProgress: false,
                fetchingObservationsFailed: false
            }
        }
        default:
            return state
    }
}

export const saveNewObservation = (obs) => {
    return dispatch => {
        dispatch({
            type: INSERT_OBSERVATION,
            observation: obs
        });
    }
}

export const deleteObservation = (obs) => {
    return dispatch => {
        dispatch({
            type: DELETE_OBSERVATION,
            observation: obs
        });
    }
}

export const updateObservation = (obs) => {
    return dispatch => {
        dispatch({
            type: UPDATE_OBSERVATION,
            observation: obs
        });
    }
}