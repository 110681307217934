import Ajax from './ajax'
import Mission from '../domain/mission'

export const MISSION_UPDATED = 'missions/MISSION_UPDATED'

export const UPDATE_MISSION = 'missions/UPDATE_MISSION'
export const SAVE_NEW_MISSION = 'missions/SAVE_NEW_MISSION'
export const DELETE_MISSION = 'missions/DELETE_MISSION'

const initialState = {
    missions: null,
    fetchingMissionsInProgress: false,
    fetchingMissionsFailed: false,
}

export default (state = initialState, action) => {
    switch (action.type) {

        case MISSION_UPDATED: {
            let newMissions = [];
            for(let m of state.missions) {
                if (m.id !== action.mission.id)
                    newMissions.push(m);
            }
            newMissions.push(new Mission(action.mission));
            return {
                ...state,
                missions: newMissions,
            }
        }
        case 'DATA_UPDATED_missions': {
            let newMissions = [];
            for (let m of action.data) {
                newMissions.push(new Mission(m));
            }
            return {
                ...state,
                missions: newMissions,
                fetchingMissionsInProgress: false,
                fetchingMissionsFailed: false
            }
        }
        default:
            return state
    }
}

export const saveNewMission = (mission) => {
    return dispatch => {
        dispatch({type: SAVE_NEW_MISSION, mission: mission});
    }
}

export const updateMission = (mission) => {
    return dispatch => {
        dispatch({type: UPDATE_MISSION, mission: mission});
    }
}

export const deleteMission = (mission) => {
    return dispatch => {
        dispatch({type: DELETE_MISSION, mission: mission});
    }
}
