import axios from 'axios'
import CONFIG from '../config'
import store from '../store'
import { AuthenticationFailed } from './authentication'


axios.interceptors.response.use(null, function(error){
    if (error.response) {
        if (error.response.status === 401) {  
            store.dispatch(AuthenticationFailed());            
        }
    } else if (error.request) {
    } else {
    }
    return Promise.reject(error);
})

function getAuthStr() {
    return "Bearer " + localStorage.getItem('accessToken')
}

const Ajax =  {
    get(url) {
        const promise = new Promise(function(resolve, reject) {
            axios.get(CONFIG.backendUrl + url, {withCredentials: true, headers: { 'Authorization': getAuthStr() } })
            .then(function(result){
                resolve(result);
            }).catch(function(err){
                reject(err);
            });
        });
        return promise;
    },
    post(url, jsondata) {
        const promise = new Promise(function(resolve, reject) {
            axios({
                method: 'post',
                withCredentials: true,
                url: CONFIG.backendUrl + url,
                data: JSON.stringify(jsondata),
                headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': getAuthStr() }})
            .then(function(result){
                resolve(result);
            }).catch(function(err){
                reject(err);
            });
        });
        return promise;
    },
    put(url, jsondata) {
        const promise = new Promise(function(resolve, reject) {
            axios({
                method: 'put',
                withCredentials: true,
                url: CONFIG.backendUrl + url,
                data: JSON.stringify(jsondata),
                headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': getAuthStr() }})
            .then(function(result){
                resolve(result);
            }).catch(function(err){
                reject(err);
            });
        });
        return promise;
    },
    delete(url) {
        const promise = new Promise(function(resolve, reject) {
            axios({
                method: 'delete',
                withCredentials: true,
                url: CONFIG.backendUrl + url,
                headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': getAuthStr() }
            }).then(function(result){
                resolve(result);
            }).catch(function(err){
                reject(err);
            });
        });
        return promise;
    }
}

export default Ajax