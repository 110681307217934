import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent';
import blue from '@material-ui/core/colors/blue';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Mission from '../domain/mission';
import Unit from '../domain/unit';
import User from '../domain/user';
import Position from '../domain/position';
import Observation from '../domain/observation';
import Report from '../domain/report';
import { REPORT_TYPE } from '../constants'
import ObservationEditDialogContent from './dialogs/observationEditDialog';

const styles = {
    heading: {
        fontSize: "1.25em",
    },
    textField: {
        width: "100%",
    },
    select: {
        width: "100%",
    },
    button: {
        marginLeft: "10px",
    },
    smaller: {
        fontSize: "0.75em",
    }
};

class MapLongPressActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            missionStatus: '',
            missionTask : '',
            missionTroops: '',
            missionadditionalInformation: '',
            missionReport : '',
            selectedResponsibleId: 0,
            observationText: '',
            reportType: ''
        };
    }

    handleClose = () => {
        this.setState({
            missionStatus: '',
            missionTask : '',
            missionTroops: '',
            missionadditionalInformation: '',
            missionReport : '',
            selectedResponsibleId: 0,
            observationText: '',
            reportType: ''
        });
        this.props.onClose();
    }

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.value });
    }

    addMissionBtnDisabled = () => {
        if (this.state.missionTask.length > 0 && this.state.selectedResponsibleId != 0)
            return false

        return true;
    }

    onClickSaveMission = () => {
        let mission = new Mission();
        mission.position = new Position();
        mission.responsible = new User();
        mission.status = this.state.missionStatus;
        mission.task = this.state.missionTask;
        mission.troops = this.state.missionTroops;
        mission.additionalInformation = this.state.missionadditionalInformation;
        mission.report = this.state.missionReport;
        mission.responsible.id = this.state.selectedResponsibleId;
        mission.position.latitude = this.props.position.lat;
        mission.position.longitude = this.props.position.lng;
        mission.state = "u";
        this.props.saveNewMission(mission);
        this.handleClose();
    }

    onClickSaveObservation = (text, type, radius, img) => {
        let obs = new Observation();
        obs.position.latitude = this.props.position.lat;
        obs.position.longitude = this.props.position.lng;
        obs.description = text;
        obs.type = type;
        obs.radius = radius ? radius : 0;
        obs.reporter = this.props.userProfile;
        obs.img = img;
        this.props.saveNewObservation(obs);
        this.props.onClose();
    }

    onClickCloseBtn = () => {
        this.props.onClose();
    }
    unitsToFlatList = (units) => {
        let result = [];
        if (units != null && units.length > 0) {
            for (let u of units) {
                if (u.subUnits != null && u.subUnits.length > 0) {
                    result = result.concat(this.unitsToFlatList(u.subUnits));
                }
                result.push(u);
            }
        }
        return result;
    }
    onSelectReport = (reporttype) => {
        this.setState({...this.state, reportType: reporttype});
    }
    onSendReport = () => {
        let report = new Report();
        report.position.latitude = this.props.position.lat;
        report.position.longitude = this.props.position.lng;
        report.type = this.state.reportType;
        report.reporter.id = this.props.userProfile.id;
        this.props.saveNewReport(report);
        this.handleClose();
    }

    render() {
        const { position, onClose, selectedValue, ...other } = this.props;
        const units = this.unitsToFlatList(this.props.units);
        let actionName = "";
        if (this.props.openMission)
            actionName = "Uusi tehtävä";
        else if (this.props.openObs)
            actionName =  "Uusi havainto";
        else
            actionName = "Ilmoitus";
        return (
            <Dialog open={this.props.openMission || this.props.openObs || this.props.openReport} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">{actionName}<br/>
                    <span className={this.props.classes.smaller}>{position.lat}, {position.lng}</span>
                </DialogTitle>
                {this.props.openMission &&
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                label="Tilanne"
                                multiline
                                rowsMax="4"
                                value={this.state.missionStatus}
                                onChange={this.handleChange('missionStatus')}
                                className={this.props.classes.textField}
                                margin="normal"
                            />
                        </Grid>                        
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                label="Tehtävä"
                                multiline
                                rowsMax="4"
                                value={this.state.missionTask}
                                onChange={this.handleChange('missionTask')}
                                className={this.props.classes.textField}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                label="Joukot"
                                multiline
                                rowsMax="4"
                                value={this.state.missionTroops}
                                onChange={this.handleChange('missionTroops')}
                                className={this.props.classes.textField}
                                margin="normal"
                            />
                        </Grid>                       
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                label="Lisämääreet"
                                multiline
                                rowsMax="4"
                                value={this.state.missionadditionalInformation}
                                onChange={this.handleChange('missionadditionalInformation')}
                                className={this.props.classes.textField}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                label="Raportti"
                                multiline
                                rowsMax="4"
                                value={this.state.missionReport}
                                onChange={this.handleChange('missionReport')}
                                className={this.props.classes.textField}
                                margin="normal"
                            />
                        </Grid>                           
                        <Grid item xs={12}>
                            <InputLabel htmlFor="selectedTeamId">Valittu yksikkö</InputLabel>
                            <Select
                                value={this.state.selectedResponsibleId}
                                onChange={this.handleChange('selectedResponsibleId')}
                                className={this.props.classes.select}
                                inputProps={{
                                    name: 'selectedResponsibleId',
                                    id: 'selectedResponsibleId',
                                }}>
                                <MenuItem value="0">
                                    <em>-Valitse-</em>
                                </MenuItem>
                                {units != null && units.map(item =>
                                    <MenuItem key={item.id} value={item.leader.id}>{item.name} ({item.leader.userName})</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                disabled={this.addMissionBtnDisabled()}
                                onClick={this.onClickSaveMission}>
                                Tallenna
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={this.props.classes.button}
                                onClick={this.onClickCloseBtn}>
                                Sulje
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                }
                {this.props.openObs &&
                <ObservationEditDialogContent observation={this.props.openObs} classes={this.props.classes} onClickSaveObservation={this.onClickSaveObservation} onClickCloseBtn={this.onClickCloseBtn} />
                }
                {this.props.openReport &&
                <DialogContent>
                    <Grid container spacing={24}>
                        {this.state.reportType.length == 0 &&
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                className={this.props.classes.button}
                                onClick={() => this.onSelectReport(REPORT_TYPE.CONTACT)}>
                                {REPORT_TYPE.CONTACT}
                            </Button>
                            <Button
                                color="primary"
                                className={this.props.classes.button}
                                onClick={() => this.onSelectReport(REPORT_TYPE.OBSERVATION)}>
                                {REPORT_TYPE.OBSERVATION}
                            </Button>
                            <Button
                                color="primary"
                                className={this.props.classes.button}
                                onClick={() => this.onSelectReport(REPORT_TYPE.M_USAGE)}>
                                {REPORT_TYPE.M_USAGE}
                            </Button>
                            <Button
                                color="primary"
                                className={this.props.classes.button}
                                onClick={() => this.onSelectReport(REPORT_TYPE.INDIRECT)}>
                                {REPORT_TYPE.INDIRECT}
                            </Button>
                        </Grid>
                        }
                        {this.state.reportType.length > 0 &&
                        <Grid item xs={12}>
                            <strong>{this.state.reportType}</strong>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            {this.state.reportType.length > 0 &&
                            <Button
                                color="primary"
                                variant="contained"
                                className={this.props.classes.button}
                                onClick={this.onSendReport}>
                                Ilmoita
                            </Button>
                            }
                            <Button
                                color="primary"
                                variant="contained"
                                className={this.props.classes.button}
                                onClick={this.handleClose}>
                                Peruuta
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                }
            </Dialog>
        );
    }
}

MapLongPressActions.propTypes = {
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
    location: PropTypes.object,
    saveNewMission: PropTypes.func
};

export default withMobileDialog({breakpoint: 'sm'})(withStyles(styles)(MapLongPressActions));