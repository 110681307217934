import Position from './position'
import User from './user'
import moment from 'moment'
import { OBS_TYPE } from './../constants'

class Observation {
    constructor(json) {
        if(json != null) {
            this.id = json.id;
            this.position = new Position(json.position);
            this.type = json.type;
            this.description = json.description;
            this.firstObserved = moment(json.firstObserved);
            this.updated = moment(json.updated);
            this.reporter = new User(json.reporter);
            this.img = json.img;
            this.imgId = json.imgId;
            this.radius = json.radius;
            this.ack = json.ack;
        } else {
            this.id = Math.floor(Math.random() * 1000000) + 1;
            this.position = new Position();
            this.type = OBS_TYPE.YLEINEN;
            this.description = '';
            this.firstObserved = moment();
            this.updated = moment();
            this.reporter = new User();
            this.img = '';
            this.imgId = '';
            this.radius = 0;
            this.ack = false;
        }
    }
}

export default Observation;