
const TAG = 'mobile_interface';
const METHOD_VIBRATE = 'vibrate';
const METHOD_GET_LOCATION = 'getLocation';
const METHOD_GET_MOBILE_ID = 'getMobileId';
const METHOD_UPDATE_TOKEN = 'updateToken';
const METHOD_CONFERENCE_CALL = 'makeConferenceCall';

export const LOCATION_FETCHED = 'mobile/LOCATION_FETCHED';
export const LOCATION_FETCH_FAILED = 'mobile/LOCATION_FETCH_FAILED';
export const MOBILE_ID_FETCHED = 'mobile/MOBILE_ID_FETCHED';
export const MOBILE_ID_FETCH_FAILED = 'mobile/MOBILE_ID_FETCH_FAILED';
export const UPDATE_MOBILE_ID = 'mobile/UPDATE_MOBILE_ID';
export const SEND_MESSAGE = 'mobile/SEND_MESSAGE';

const initialState = {
    location: null,
    locationFetchFailed: false,
    mobileId: null,
    mobileIdFetchFailed: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_FETCHED:
            return {
                ...state,
                location: action.location,
                locationFetchFailed: false
            }
        case LOCATION_FETCH_FAILED:
            return {
                ...state,
                locationFetchFailed: true
            }
        case MOBILE_ID_FETCHED:
            return {
                ...state,
                mobileId: action.mobileId,
                mobileIdFetchFailed: false
            }
        case MOBILE_ID_FETCH_FAILED:
            return {
                ...state,
                mobileIdFetchFailed: true
            }
        default:
            return state
    }
}

const Mobile = { 
    vibrate(millis) {
        this._log('vibrate called (ms): ' + millis);
        if (!this._checkMethod(METHOD_VIBRATE)) {
            return;
        }
        window.Android.vibrate(millis);
    },

    getLocation() {
        this._log('getLocation called');
        if (!this._checkMethod(METHOD_GET_LOCATION)) {
            return null;
        }
        const locationJson = window.Android.getLocation();
        this._log('locationJson: ' + locationJson);
        return JSON.parse(locationJson);
    },

    getMobileId() {
        this._log('getMobileId called');
        if (!this._checkMethod(METHOD_GET_MOBILE_ID)) {
            return null;
        }
        const mobileIdJson = window.Android.getMobileId();
        this._log('mobileIdJson: ' + mobileIdJson);
        return JSON.parse(mobileIdJson);
    },

    updateToken(token) {
        this._log('updateToken called');
        if (!this._checkMethod(METHOD_UPDATE_TOKEN)) {
            return null;
        }
        window.Android.updateToken(token);
    },

    conferenceCall(phoneNumbers) {
        this._log('conferenceCall called');
        if (!this._checkMethod(METHOD_CONFERENCE_CALL)) {
            return;
        }
        window.Android.makeConferenceCall(phoneNumbers);
    },

    _checkMethod(method) {
        if (typeof window.Android === 'undefined' || window.Android == null) {
            this._log('No existing mobile interface');
            return false;
        }
        if (typeof window.Android[method] !== "function") {
            this._log('No existing mobile interface method ' + method);
            return false;
        }
        return true;
    },

    _log(msg) {
        console.log(TAG + ': ' + msg);
    }
};

export const vibrate = (millis) => {
    return dispatch => {
        Mobile.vibrate(millis);
    }
}

export const getLocation = () => {
    return dispatch => {
        const promise = new Promise(function(resolve, reject) {
            const location = Mobile.getLocation()
            resolve(location);
        });
        promise.then((location) => {
            if (location == null || location.location == null) {
                dispatch({
                    type: LOCATION_FETCH_FAILED
                })
                return;
            }
            dispatch({
                type: LOCATION_FETCHED,
                location: location.location
            })
        });
    }
}

export const getMobileId = () => {
    return dispatch => {
        const promise = new Promise(function(resolve, reject) {
            const mobileIdData = Mobile.getMobileId()
            resolve(mobileIdData);
        });
        promise.then((mobileIdData) => {
            if (mobileIdData == null || mobileIdData.mobileId == null) {
                dispatch({
                    type: MOBILE_ID_FETCH_FAILED
                })
                return;
            }
            dispatch({
                type: MOBILE_ID_FETCHED,
                mobileId: mobileIdData.mobileId
            });
            dispatch({
                type: UPDATE_MOBILE_ID,
                mobileId: mobileIdData.mobileId
            });
        });
    }
}

export const updateToken = (token) => {
    Mobile.updateToken(token);
}

export const conferenceCall = (numbers) => {
    return dispatch => {
        Mobile.conferenceCall(numbers);
    }
}

export const sendMessage = (title, text, mobileIds) => {
    return dispatch => {
        dispatch({
            type: SEND_MESSAGE,
            title: title,
            text: text,
            mobileIds: mobileIds
        });
    }
}

export const isMobile = () => {
    return typeof window.Android !== 'undefined' && window.Android != null;
}
