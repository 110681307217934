import Ajax from './ajax'
import UserPosition from '../domain/positionupdate'
import {UPDATE_UNIT} from "./units";

export const GET_POSITIONS_IN_PROGRESS = 'positions/GET_POSITIONS_IN_PROGRESS'
export const GET_POSITIONS_SUCCESS = 'positions/GET_POSITIONS_SUCCESS'
export const GET_POSITIONS_FAIL = 'positions/GET_POSITIONS_FAIL'
export const UPDATE_POSITION = 'positions/UPDATE_POSITION'

const initialState = {
    positions: null, // positions per user, dictionary -> js object. Access: "positions[user_id]"
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_UPDATED_positions': {
            let newPositions = [];
            let keys = Object.keys(action.data);
            for (let k of keys) {
                let positionsForUser = [];
                if (action.data[k] != null && action.data[k].length > 0) {
                    for(let p of action.data[k]) {
                        positionsForUser.push(new UserPosition(p));
                    }
                }
                newPositions[k] = positionsForUser;
            }
            return {
                ...state,
                positions: newPositions,
                fetchingPositionsInProgress: false,
                fetchingPositionsFailed: false
            }
        }
        default:
            return state
    }
}

export const updatePosition = (userId, position) => {
    return dispatch => {
        dispatch({type: UPDATE_POSITION, userId: userId, position: position});
    }
}