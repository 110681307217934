

class Position {
    constructor(json) {
        if (json != null) {
            this.latitude = json.latitude;
            this.longitude = json.longitude;
            this.altitude = json.altitude;
            this.horizontalAccuracy = json.horizontalAccuracy;
            this.verticalAccuracy = json.verticalAccuracy;
            this.speed = json.speed;
            this.course = json.course;
        } else {
            this.latitude = 0;
            this.longitude = 0;
            this.altitude = 0;
            this.horizontalAccuracy = 0;
            this.verticalAccuracy = 0;
            this.speed = 0;
            this.course = 0;
        }
    }
}

export default Position