import Ajax from './ajax'
import User from '../domain/user'

export const OPEN_SETTING_DIALOG = 'user/OPEN_SETTING_DIALOG'
export const CLOSE_SETTING_DIALOG = 'user/CLOSE_SETTING_DIALOG'
export const UPDATE_USER = 'user/UPDATE_USER';

const initialState = {
    isSettingsDialogOpen: false,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case OPEN_SETTING_DIALOG: {
            return {
                ...state,
                isSettingsDialogOpen: true
            }
        }
        case CLOSE_SETTING_DIALOG: {
            return {
                ...state,
                isSettingsDialogOpen: false
            }
        }
        default:
            return state
    }
}   

export const openSettingsDialog = () => {
    return dispatch => {
        dispatch({type: OPEN_SETTING_DIALOG});
    }
}

export const updateUser = (u) => {
    return dispatch => {
        dispatch({type: UPDATE_USER, user: u });
    }
}

export const closeSettingsDialog = () => {
    return dispatch => {
        dispatch({type: CLOSE_SETTING_DIALOG});
    }
}
